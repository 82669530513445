<template>
  <div class="maxBox">
    <div class="content">
      <div class="TextBox">
        <div class="item">
          <div class="tit">
            <div>无人</div>
            <div class="triangle">▶</div>
          </div>
          <div class="details">
            用户使用友动体育小程序直接扫码开门，选择球桌开始计费灯自动打开，点击结束计费，灯自动熄灭。全程不需服务人员,大大节省人力成本。
          </div>
        </div>
        <div class="item">
          <div class="tit">
            <div>模式</div>
            <div class="triangle">▶</div>
          </div>
          <div class="details">
            友动体育支持独享、共享、抢台费多种娱乐模式任你选。
            <ul>
              <li>独享，自己玩球不受他人打扰。</li>
              <li>
                共享，两人共享一张球桌，球桌费用平分。完美解决两人打球谁结账的尴尬问题。
              </li>
              <li>抢台费，谁输谁结台费，增加竞技性，趣味性。</li>
            </ul>
          </div>
        </div>
        <div class="item">
          <div class="tit">
            <div>智能</div>
            <div class="triangle">▶</div>
          </div>
          <div class="details hove">
            扫码开门，智能监控获取实时动态，拥有商家后台盈利状况一目了然。
          </div>
        </div>
      </div>
      <div class="shopBox">
        <div class="shop animated slideInLeft">
          <img class="tit" src="../assets/img/shop_1/tit.png" alt="" />
          <div class="imgBox">
            <img src="../assets/img/shop_1/bg1.jpg" />
            <img src="../assets/img/shop_1/bg2.jpg" />
            <img src="../assets/img/shop_1/bg3.png" />
            <img src="../assets/img/shop_1/bg4.png" />
          </div>
        </div>
        <div class="shop animated slideInRight">
          <img class="tit" src="../assets/img/shop_2/tit.png" alt="" />
          <div class="imgBox">
            <img src="../assets/img/shop_2/bg1.png" />
            <img src="../assets/img/shop_2/bg2.png" />
            <img src="../assets/img/shop_2/bg3.jpg" />
            <img src="../assets/img/shop_2/bg4.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.maxBox {
  min-height: calc(100vh - 120px);
  padding-top: 120px;
  background: url("../assets/img/about_bg2.jpg") 100% no-repeat;
  background-size: cover;
  // background: #bec9ec;
}
.content {
  .shopBox {
    display: flex;
    justify-content: space-between;
    .shop {
      margin-bottom: 20px;
      .tit {
        width: 200px;
        margin-bottom: 20px;
      }
      .imgBox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 20px;
        width: 600px;
        text-align: center;
        // border: 1px solid #000;
        img {
          width: 280px;
          height: 250px;
          padding: 5px;
          margin: 5px;
          border-radius: 10px;
          box-shadow: 1px 1px 5px #000;
        }
        img:hover {
          transform: scale(1.05);
          transition: all 0.5s;
        }
      }
    }
  }
  .TextBox {
    padding: 20px 0px;
    .item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 40px;
      .tit {
        width: 120px;
        height: 60px;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: 60px;
        background: rgb(245, 241, 241);
        border-left: 4px solid #553db4;
        border-radius: 6px;
        box-shadow: 2px 2px 2px #c1c7cc;
        display: flex;
        justify-content: space-evenly;
        .triangle {
          color: #553db4;
        }
      }
      .details:hover {
        transform: scale(1.01);
      }
      .details {
        width: 750px;
        // height: 60px;
        padding: 20px;
        font-size: 20px;
        line-height: 1.5em;
        background: rgb(245, 241, 241);
        border-radius: 6px;
        box-shadow: 3px 3px 4px #c1c7cc;
        ul {
          margin-left: 20px;
        }
        li {
          list-style: square;
        }
      }
    }
  }
}
</style>
